<template>
  <core-element-modal-window
    :title="$t('overview.editUserProfile')"
    :has-button-slot="true"
    class="text-center"
    :on-modal-changed="resetForms"
  >
    <template #activator-button="{on}">
      <a
        href="#"
        class="d-none d-sm-block ml-2 text-truncate text-underline"
        data-cy="profile-edit-link"
        v-on="on"
      >
        {{ fullName }}
      </a>
    </template>
    <template #content>
      <v-card-text>
        <material-profile-setting-form ref="profileSettingForm" />
      </v-card-text>
    </template>
  </core-element-modal-window>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('client', ['clients']),
    routeClientId () {
      return this.$route.params.clientId
    },
    fullName () {
      return this.clients[this.routeClientId].fullName
    }
  },
  methods: {
    resetForms (val) {
      this.$nextTick(() => {
        if (val) {
          this.$refs.profileSettingForm.resetForm()
        }
      })
    }
  }
}
</script>

<style>
.text-underline {
  text-decoration: underline!important;
}
</style>
